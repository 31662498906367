import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    [
      _c(
        VRow,
        [
          _c(
            VCol,
            { attrs: { cols: "12" } },
            [
              _c(
                VForm,
                { on: { submit: _vm.save } },
                [
                  _c(
                    VRow,
                    [
                      _c(VCol, { attrs: { cols: "12" } }, [
                        _c("h2", [_vm._v("Edit Entry")]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "4" } },
                        [
                          _c(VTextField, {
                            attrs: { label: "First Name", dense: "" },
                            model: {
                              value: _vm.offender.first_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.offender, "first_name", $$v)
                              },
                              expression: "offender.first_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "4" } },
                        [
                          _c(VTextField, {
                            attrs: { label: "Last Name", dense: "" },
                            model: {
                              value: _vm.offender.last_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.offender, "last_name", $$v)
                              },
                              expression: "offender.last_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "4" } },
                        [
                          _c(VSelect, {
                            attrs: {
                              label: "Gender",
                              dense: "",
                              items: ["M", "F"],
                            },
                            model: {
                              value: _vm.offender.gender,
                              callback: function ($$v) {
                                _vm.$set(_vm.offender, "gender", $$v)
                              },
                              expression: "offender.gender",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "4" } },
                        [
                          _c(VTextField, {
                            attrs: { label: "Tags", dense: "" },
                            model: {
                              value: _vm.offender.tagstr,
                              callback: function ($$v) {
                                _vm.$set(_vm.offender, "tagstr", $$v)
                              },
                              expression: "offender.tagstr",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "4" } },
                        [
                          _c(VSelect, {
                            attrs: {
                              label: "Review Status",
                              dense: "",
                              items: ["pass", "review", "fail", ""],
                            },
                            model: {
                              value: _vm.offender.reviewStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.offender, "reviewStatus", $$v)
                              },
                              expression: "offender.reviewStatus",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "4" } },
                        [
                          _c(VSelect, {
                            attrs: {
                              label: "Modified by",
                              dense: "",
                              items: _vm.store.config.userOptions,
                              "item-value": "id",
                              "item-text": "email",
                            },
                            model: {
                              value: _vm.offender.modifiedBy,
                              callback: function ($$v) {
                                _vm.$set(_vm.offender, "modifiedBy", $$v)
                              },
                              expression: "offender.modifiedBy",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "4" } },
                        [
                          _c(VTextField, {
                            attrs: { label: "Offender ID", dense: "" },
                            model: {
                              value: _vm.offender.offender_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.offender, "offender_id", $$v)
                              },
                              expression: "offender.offender_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "4" } },
                        [
                          _c(VTextField, {
                            attrs: { label: "Image Description", dense: "" },
                            model: {
                              value: _vm.offender.image_description,
                              callback: function ($$v) {
                                _vm.$set(_vm.offender, "image_description", $$v)
                              },
                              expression: "offender.image_description",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "4" } },
                        [
                          _c(VTextField, {
                            attrs: { label: "Image Location", dense: "" },
                            model: {
                              value: _vm.offender.image_location,
                              callback: function ($$v) {
                                _vm.$set(_vm.offender, "image_location", $$v)
                              },
                              expression: "offender.image_location",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "4" } },
                        [
                          _c(VTextField, {
                            attrs: { label: "Description", dense: "" },
                            model: {
                              value: _vm.offender.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.offender, "description", $$v)
                              },
                              expression: "offender.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "4" } },
                        [
                          _c(VTextField, {
                            attrs: { label: "Login", dense: "" },
                            model: {
                              value: _vm.offender.login,
                              callback: function ($$v) {
                                _vm.$set(_vm.offender, "login", $$v)
                              },
                              expression: "offender.login",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "4" } },
                        [
                          _c(VTextField, {
                            attrs: { label: "Original Filename", dense: "" },
                            model: {
                              value: _vm.offender.original_filename,
                              callback: function ($$v) {
                                _vm.$set(_vm.offender, "original_filename", $$v)
                              },
                              expression: "offender.original_filename",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "4" } },
                        [
                          _c(VTextField, {
                            attrs: { label: "S3 Path", dense: "" },
                            model: {
                              value: _vm.offender.s3_path,
                              callback: function ($$v) {
                                _vm.$set(_vm.offender, "s3_path", $$v)
                              },
                              expression: "offender.s3_path",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "4" } },
                        [
                          _c(VTextField, {
                            attrs: { label: "Filename", dense: "" },
                            model: {
                              value: _vm.offender.filename,
                              callback: function ($$v) {
                                _vm.$set(_vm.offender, "filename", $$v)
                              },
                              expression: "offender.filename",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(VBtn, { attrs: { type: "submit" } }, [
                    _vm._v("Submit"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }