import { define, auto, type, Store } from 'type-r'
import config, {Config} from '../config'
import User from './user'
import Offender from './offender'
import Settings from './config'

@define
export default class SiteStore extends Store {
    @type(User).as user: User
    @type(Config).as config : Config
    @type(Offender).as offender : Offender
    @type(User).as edituser: User
    @type(Settings).as settings: Settings
    
    initialize() {
        if (!this.user)
            this.user = new User();
        if (!this.edituser)
            this.edituser = new User();        
        if (!this.config)
            this.config = new Config();
        if (!this.offender)
            this.offender = new Offender();
    }
}
