import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRangeSlider } from 'vuetify/lib/components/VRangeSlider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "offender" } },
    [
      _c(
        VContainer,
        [
          _c(
            VRow,
            [
              _c(
                VCol,
                { attrs: { cols: "12" } },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "12" } },
                        [
                          _c(VDataTable, {
                            ref: "table",
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.items,
                              "item-key": "id",
                              search: _vm.filter.search,
                              loading: _vm.loading,
                              "show-select": "",
                              "server-items-length": _vm.totalItems,
                              options: _vm.options,
                            },
                            on: {
                              "update:options": function ($event) {
                                _vm.options = $event
                              },
                              "item-selected": _vm.selchange,
                              "click:row": _vm.selchange,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "top",
                                fn: function () {
                                  return [
                                    _c(
                                      VRow,
                                      [
                                        _c(
                                          VCol,
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(VTextField, {
                                              attrs: {
                                                "append-icon": "mdi-magnify",
                                                label: "Search",
                                                "single-line": "",
                                                "hide-details": "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.getDataFromApi(
                                                    $event,
                                                    false
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.filter.search,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.filter,
                                                    "search",
                                                    $$v
                                                  )
                                                },
                                                expression: "filter.search",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      VRow,
                                      [
                                        _c(
                                          VCol,
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              VExpansionPanels,
                                              [
                                                _c(
                                                  VExpansionPanel,
                                                  [
                                                    _c(
                                                      VExpansionPanelHeader,
                                                      [
                                                        _vm._v(
                                                          "Advanced Settings"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      VExpansionPanelContent,
                                                      [
                                                        _c(
                                                          VRow,
                                                          [
                                                            _c(
                                                              VCol,
                                                              {
                                                                attrs: {
                                                                  cols: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(VSelect, {
                                                                  attrs: {
                                                                    multiple:
                                                                      "",
                                                                    dense: "",
                                                                    items:
                                                                      _vm.config
                                                                        .tagOptions,
                                                                    clearable: true,
                                                                    label:
                                                                      "Tag Filter",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      _vm.getDataFromApi,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.filter
                                                                        .tags,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.filter,
                                                                          "tags",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "filter.tags",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              VCol,
                                                              {
                                                                attrs: {
                                                                  cols: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  VCheckbox,
                                                                  {
                                                                    staticClass:
                                                                      "d-inline-block",
                                                                    attrs: {
                                                                      dense: "",
                                                                      label:
                                                                        "Fail",
                                                                      value:
                                                                        "fail",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        _vm.getDataFromApi,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .filter
                                                                          .reviewed,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.filter,
                                                                            "reviewed",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "filter.reviewed",
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  VCheckbox,
                                                                  {
                                                                    staticClass:
                                                                      "d-inline-block",
                                                                    attrs: {
                                                                      dense: "",
                                                                      label:
                                                                        "Review",
                                                                      value:
                                                                        "review",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        _vm.getDataFromApi,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .filter
                                                                          .reviewed,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.filter,
                                                                            "reviewed",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "filter.reviewed",
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  VCheckbox,
                                                                  {
                                                                    staticClass:
                                                                      "d-inline-block",
                                                                    attrs: {
                                                                      dense: "",
                                                                      label:
                                                                        "Pass",
                                                                      value:
                                                                        "pass",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        _vm.getDataFromApi,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .filter
                                                                          .reviewed,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.filter,
                                                                            "reviewed",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "filter.reviewed",
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  VCheckbox,
                                                                  {
                                                                    staticClass:
                                                                      "d-inline-block",
                                                                    attrs: {
                                                                      dense: "",
                                                                      label:
                                                                        "Not Review",
                                                                      value:
                                                                        "unreviewed",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        _vm.getDataFromApi,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .filter
                                                                          .reviewed,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.filter,
                                                                            "reviewed",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "filter.reviewed",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              VCol,
                                                              {
                                                                attrs: {
                                                                  cols: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  VRangeSlider,
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Face Confidence",
                                                                      "thumb-label":
                                                                        "always",
                                                                      dense: "",
                                                                      min: "0",
                                                                      max: "100",
                                                                    },
                                                                    on: {
                                                                      end: _vm.getDataFromApi,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .filter
                                                                          .range,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.filter,
                                                                            "range",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "filter.range",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          VRow,
                                                          [
                                                            _c(
                                                              VCol,
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(VSelect, {
                                                                  attrs: {
                                                                    label:
                                                                      "Label Filter",
                                                                    dense: "",
                                                                    multiple:
                                                                      "",
                                                                    items:
                                                                      _vm.config
                                                                        .labelOptions,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.filter
                                                                        .labels,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.filter,
                                                                          "labels",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "filter.labels",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              VCol,
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(VSelect, {
                                                                  attrs: {
                                                                    label:
                                                                      "User filter",
                                                                    dense: "",
                                                                    "item-text":
                                                                      "email",
                                                                    "item-value":
                                                                      "id",
                                                                    items:
                                                                      _vm.config
                                                                        .userOptions,
                                                                    multiple:
                                                                      "",
                                                                    clearable: true,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.filter
                                                                        .user,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.filter,
                                                                          "user",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "filter.user",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              VRow,
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.showbulk,
                                                    expression: "showbulk",
                                                  },
                                                ],
                                                staticClass: "mt-4",
                                              },
                                              [
                                                _c(
                                                  VCol,
                                                  { attrs: { cols: "8" } },
                                                  [
                                                    _c(VSelect, {
                                                      attrs: {
                                                        id: "bulkReview",
                                                        dense: "",
                                                        items: [
                                                          "pass",
                                                          "review",
                                                          "fail",
                                                          "unreview",
                                                        ],
                                                        label: "Bulk Review",
                                                      },
                                                      model: {
                                                        value: _vm.bulkReview,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.bulkReview = $$v
                                                        },
                                                        expression:
                                                          "bulkReview",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  VCol,
                                                  { attrs: { cols: "4" } },
                                                  [
                                                    _c(
                                                      VBtn,
                                                      {
                                                        attrs: {
                                                          id: "bulkBtn",
                                                          dense: "",
                                                          "x-small": "",
                                                          color: "primary",
                                                          elevation: "2",
                                                        },
                                                        on: {
                                                          click: _vm.bulkSubmit,
                                                        },
                                                      },
                                                      [_vm._v("Submit")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "item.photo",
                                fn: function ({ item }) {
                                  return [
                                    _c("img", {
                                      staticClass: "thumb",
                                      attrs: {
                                        "data-id": item.id,
                                        src: "/offender/" + item.id + "/image",
                                      },
                                    }),
                                    _c("canvas", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: false,
                                          expression: "false",
                                        },
                                      ],
                                      attrs: {
                                        title: item.FILENAME,
                                        alt: "item.FILENAME",
                                      },
                                    }),
                                  ]
                                },
                              },
                              {
                                key: "item.name",
                                fn: function ({ item }) {
                                  return [
                                    _vm._v(
                                      _vm._s(
                                        item.first_name + " " + item.last_name
                                      )
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item.gender",
                                fn: function ({ item }) {
                                  return [_vm._v(_vm._s(item.gender))]
                                },
                              },
                              {
                                key: "item.tagstr",
                                fn: function ({ item }) {
                                  return [
                                    item.tags && item.tags.length
                                      ? [
                                          _vm._l(
                                            _vm.uniquetags(item.tags),
                                            function (tag) {
                                              return [_vm._v(_vm._s(tag))]
                                            }
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                },
                              },
                              {
                                key: "item.textstr",
                                fn: function ({ item }) {
                                  return [
                                    item.texts && item.texts.length
                                      ? _vm._l(
                                          _vm.uniquetexts(item.texts),
                                          function (text) {
                                            return _c(
                                              VChip,
                                              {
                                                key: text.id,
                                                staticClass: "ma-2",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(text.detectedText)
                                                ),
                                              ]
                                            )
                                          }
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                              {
                                key: "item.modifiedByStr",
                                fn: function ({ item }) {
                                  return [
                                    _vm._v(
                                      _vm._s(_vm.username(item.modifiedBy))
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item.last_updated_at",
                                fn: function ({ item }) {
                                  return [
                                    _vm._v(
                                      _vm._s(_vm.lastUpdatedAt(item, "l LTS"))
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item.btn",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      VBtnToggle,
                                      {
                                        on: {
                                          change: function ($event) {
                                            return _vm.reviewItem($event, item)
                                          },
                                        },
                                        model: {
                                          value: item.reviewStatus,
                                          callback: function ($$v) {
                                            _vm.$set(item, "reviewStatus", $$v)
                                          },
                                          expression: "item.reviewStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          VBtn,
                                          {
                                            attrs: {
                                              fab: "",
                                              "x-small": "",
                                              dense: "",
                                              value: "review",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.clearItem(
                                                  $event,
                                                  item,
                                                  "review"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(VIcon, [
                                              _vm._v("mdi-television"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          VBtn,
                                          {
                                            attrs: {
                                              fab: "",
                                              "x-small": "",
                                              dense: "",
                                              value: "pass",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.clearItem(
                                                  $event,
                                                  item,
                                                  "pass"
                                                )
                                              },
                                            },
                                          },
                                          [_c(VIcon, [_vm._v("mdi-check")])],
                                          1
                                        ),
                                        _c(
                                          VBtn,
                                          {
                                            attrs: {
                                              fab: "",
                                              "x-small": "",
                                              dense: "",
                                              value: "fail",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.clearItem(
                                                  $event,
                                                  item,
                                                  "fail"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(VIcon, [
                                              _vm._v("mdi-cancel"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item.editbtn",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/offender/" + item.id } },
                                      [_vm._v("Edit")]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "body.append",
                                fn: function () {
                                  return undefined
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }