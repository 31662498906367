import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container", attrs: { id: "dashboard" } }, [
    _c("h2", { staticClass: "text-uppercase mt-0 text-theme-colored" }, [
      _vm._v("Admin Dashboard"),
    ]),
    _c(
      "section",
      [
        _c("h4", [_vm._v("Import Service Dashboard")]),
        _c("p", [
          _vm._v(
            "When importing a new dataset: Perform each step below, in order, one at a time."
          ),
        ]),
        _c("p", [
          _vm._v(
            "The metadata file imported is expected to be an xlsx file with the following columns (in order):"
          ),
        ]),
        _c("p", [
          _vm._v(
            "OFFENDER_ID*, IMAGE_LOCATION, IMAGE_DESCRIPTION, LAST_UPDATED_BY, LAST_UPDATED_AT, ORIGINAL_FILENAME, S3_PATH, FILENAME*, STATE*"
          ),
        ]),
        _c("p", [
          _vm._v(
            "* - functional field that requires a valid value.  All other columns are required but can be blank/invalid"
          ),
        ]),
        _c(
          VRow,
          [
            _c(VCol, { attrs: { cols: "6" } }, [
              _c("p", [
                _vm._v(
                  "Purge - Removes all existing records from the database and clears image cache"
                ),
              ]),
            ]),
            _c(
              VCol,
              { attrs: { cols: "3" } },
              [
                _c(
                  VBtn,
                  {
                    attrs: { loading: _vm.isActive("purge") },
                    on: { click: _vm.runPurge },
                  },
                  [_vm._v("Purge      ")]
                ),
              ],
              1
            ),
            _c(VCol, { staticClass: "status", attrs: { cols: "3" } }),
          ],
          1
        ),
        _c("hr"),
        _c(
          VRow,
          [
            _c(VCol, { attrs: { cols: "3" } }, [
              _c("p", [
                _vm._v("Import New Records - Imports a new Excel File"),
              ]),
            ]),
            _c(VCol, { attrs: { cols: "3" } }, [
              _c(
                "form",
                {
                  attrs: {
                    id: "importForm",
                    action: "/api/import",
                    method: "POST",
                    enctype: "multipart/form-data",
                  },
                },
                [
                  _c("input", { attrs: { type: "file", name: "xls" } }),
                  _c(
                    "p",
                    [
                      _vm._v("Current file: "),
                      !_vm.filestats || !_vm.filestats.size
                        ? [_vm._v("No file uploaded")]
                        : _vm.filestats
                        ? [
                            _vm._v(_vm._s(_vm.filestats.size) + " bytes"),
                            _c("br"),
                            _vm._v("Modified: " + _vm._s(_vm.filestats.mtime)),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c("input", {
                    attrs: { type: "hidden", name: "mode", value: "import" },
                  }),
                  _c(VBtn, { attrs: { type: "submit" } }, [
                    _vm._v("Submit"),
                  ]),
                ],
                1
              ),
            ]),
            _c(
              VCol,
              { attrs: { cols: "3" } },
              [
                _c(
                  VBtn,
                  {
                    attrs: { loading: _vm.isActive("import") },
                    on: { click: _vm.runImport },
                  },
                  [_vm._v("Import")]
                ),
              ],
              1
            ),
            _c(VCol, { staticClass: "status", attrs: { cols: "3" } }),
          ],
          1
        ),
        _c("hr"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(VCol, { attrs: { cols: "6" } }, [
              _c("p", [
                _vm._v(
                  "Build Image Cache - Save images to local disk for faster display"
                ),
              ]),
            ]),
            _c(
              VCol,
              { attrs: { cols: "3" } },
              [
                _c(
                  VBtn,
                  {
                    attrs: { loading: _vm.isActive("cache") },
                    on: { click: _vm.runCache },
                  },
                  [_vm._v("Build Cache")]
                ),
              ],
              1
            ),
            _c(VCol, { staticClass: "status", attrs: { cols: "3" } }),
          ],
          1
        ),
        _c("hr"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(VCol, { attrs: { cols: "6" } }, [
              _c("p", [
                _vm._v(
                  "Rekognition - Labels - Assigns labels to image describing objects detected within it"
                ),
              ]),
            ]),
            _c(
              VCol,
              { attrs: { cols: "3" } },
              [
                _c(
                  VBtn,
                  {
                    attrs: { loading: _vm.isActive("labels") },
                    on: { click: _vm.runLabels },
                  },
                  [_vm._v("Go")]
                ),
              ],
              1
            ),
            _c(VCol, { staticClass: "status", attrs: { cols: "3" } }),
          ],
          1
        ),
        _c("hr"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(VCol, { attrs: { cols: "6" } }, [
              _c("p", [
                _vm._v("Rekognition - Faces - Finds faces within images"),
              ]),
            ]),
            _c(
              VCol,
              { attrs: { cols: "3" } },
              [
                _c(
                  VBtn,
                  {
                    attrs: { loading: _vm.isActive("faces") },
                    on: { click: _vm.runFaces },
                  },
                  [_vm._v("Go")]
                ),
              ],
              1
            ),
            _c(VCol, { staticClass: "status", attrs: { cols: "3" } }),
          ],
          1
        ),
        _c("hr"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(VCol, { attrs: { cols: "6" } }, [
              _c("p", [
                _vm._v(
                  "Rekognition - Text Detection - Finds text within images"
                ),
              ]),
            ]),
            _c(
              VCol,
              { attrs: { cols: "3" } },
              [
                _c(
                  VBtn,
                  {
                    attrs: { loading: _vm.isActive("texts") },
                    on: { click: _vm.runTexts },
                  },
                  [_vm._v("Go")]
                ),
              ],
              1
            ),
            _c(VCol, { staticClass: "status", attrs: { cols: "3" } }),
          ],
          1
        ),
        _c("hr"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(VCol, { attrs: { cols: "6" } }, [
              _c("p", [
                _vm._v(
                  "Preprocess Images - Collects metadata on images used to improve results"
                ),
              ]),
            ]),
            _c(
              VCol,
              { attrs: { cols: "3" } },
              [
                _c(
                  VBtn,
                  {
                    attrs: { loading: _vm.isActive("preprocess") },
                    on: { click: _vm.runPreprocess },
                  },
                  [_vm._v("Go")]
                ),
              ],
              1
            ),
            _c(VCol, { staticClass: "status", attrs: { cols: "3" } }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }