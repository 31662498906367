/// <reference path="types/index.d.ts" />
/// <reference path="types/shims-vue.d.ts" />
import $ from 'jquery'
import "reflect-metadata"
window.$ = window.jQuery = $
import config from './config'
import User from './models/user'
import { library, icon } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

import moment from 'moment';
var app = null;
import _ from 'underscore'

import Vue from 'vue';
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
Vue.use(Vuetify)

const opts = {}
var vuetify = new Vuetify(opts);


import VueRouter from 'vue-router';
import hullabaloo from './lib/hullabaloo'

Vue.use(VueRouter);

var current_customer, permstruct;
var pagedata = window.pagedata;
var groups:any = [];

var hulla = new hullabaloo();
hulla.options.offset = {
    from: 'bottom',
    amount: 20
};
hulla.options.align = 'right';
hulla.options.delay = 5000;
hulla.options.allow_dismiss = true;

function routeFn(route) {
    let data = {
    };
    for (var k in route.params) {
        data[k] = route.params[k];
    }
    for (var k in route.query) {
        data[k] = route.query[k];
    }
    return data;
};

import LoginView from './vue/login/index.vue'
import ResetPasswordView from './vue/reset-password/index.vue'

import OffendersView from './vue/offender/list.vue'
import OffendersEditView from './vue/offender/edit.vue'

import UsersView from './vue/admin/users/list.vue'
import UsersCreateView from './vue/admin/users/create.vue'
import UsersEditView from './vue/admin/users/edit.vue'

import DashboardView from './vue/dashboard/index.vue'
import SettingsView from './vue/settings/index.vue'

import AppMenu from './vue/app/menu.vue'

let routes = [
    { path: '/login', component: LoginView, props: routeFn },
    { path: '/reset-password', component: ResetPasswordView, props: routeFn },
        
    { path: '/offender', component: OffendersView, props: routeFn },
    { path: '/offender/:id', component: OffendersEditView, props: routeFn },
    
    { path: '/users', component: UsersView, props: routeFn },
    { path: '/users/create', component: UsersCreateView, props: routeFn},    
    { path: '/users/:id', component: UsersEditView, props: routeFn },

    { path: '/dashboard', component: DashboardView, props: routeFn },
    { path: '/settings', component: SettingsView, props: routeFn }    
];

const router = new VueRouter({
     mode: 'history',
     routes
});
import SiteStore from './models/sitestore'
export default class SiteVue extends Vue {
    hulla: any
    store: SiteStore
    config: any
    current_user: any
}
Vue.component('appmenu', AppMenu)
var user = new User();
var store = new SiteStore(pagedata, {parse:true});
$(document).ready(function () {
    if ($("#app").length) {
        window.app = app = new Vue({
            vuetify,
            router,
            template: '<v-app id="app"><appmenu></appmenu><v-main class="grey lighten-3 mt-15"><router-view></router-view></v-main></v-app>',
            data: {
                hulla,
                store
            },
            mounted: function() {
		Vue.nextTick(() => {
		    var url = new URL(window.location.href);
		    
		    this.$router.push(url.pathname + '?ts=' + (new Date()).valueOf());
		})
            },
            methods: {
                dateforge: function (dt, fmt = "MM/DD/YYYY") {
                    return moment.unix(dt).format(fmt);
                },
                customize: function() {

                },
                
                htmlDecode: function(input) {
                    var e = document.createElement('div');
                    e.innerHTML = input;
                    return e.childNodes[0].nodeValue;
                },
                init: function() {
                    // this.$router.push('/menu');
                }
            }
        });
        app.$mount('#app');
        // config.on("fetched", app.init, app);
        var loc = new URL(location.href); //get the current location
        var dir = new URL(".", loc); //get the current directory
        var rel = loc.pathname; //relative link from dir to loc

    }
});
