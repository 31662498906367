import { define, type, auto } from 'type-r'
import { Record } from './base'
@define
export class Name extends Record {
    @auto("") first: string
    @auto("") last: string
}

@define
export class Prefs extends Record {
}

@define
export default class User extends Record {
    @auto("") email: string
    @auto("") password: string
    @type(Name).as name: Name
    @auto(false) active: boolean
    @auto("") company: string
    @auto("") phone: string
    @auto("") title: string
    @type(Prefs).as prefs: Prefs
}
