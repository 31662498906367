import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VInput } from 'vuetify/lib/components/VInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    [
      _c(
        VRow,
        [
          _c(
            VCol,
            { attrs: { cols: "12" } },
            [
              _c("h2", [_vm._v("Reset Your Password")]),
              _c(
                VForm,
                {
                  ref: "form",
                  attrs: { id: "login", method: "POST" },
                  on: { submit: _vm.submit },
                },
                [
                  _c(
                    VRow,
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.submitted,
                          expression: "!submitted",
                        },
                      ],
                    },
                    [
                      _c(VInput, {
                        attrs: { type: "hidden", name: "confirmation_code" },
                        model: {
                          value: _vm.confirmation_code,
                          callback: function ($$v) {
                            _vm.confirmation_code = $$v
                          },
                          expression: "confirmation_code",
                        },
                      }),
                      _c(
                        VCol,
                        { attrs: { cols: "12" } },
                        [
                          _c(VTextField, {
                            attrs: { label: "Enter Your Email Address Here" },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: {
                                type: "submit",
                                value: "Submit",
                                loading: _vm.submitting,
                              },
                            },
                            [_vm._v("Reset Password")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }