import BaseComponent from '../../base/component'
import SiteStore from '../../../models/sitestore'
import SiteVue from '../../../site'
import Offender from '../../../models/offender'
import User from '../../../models/user'

export default BaseComponent.extend({
    data: function() {
        var store = (this.$root as SiteVue).store as SiteStore
        store.edituser = new User()
        
        return {
            edituser: store.edituser,
            store: store,
            show1: false,
	    rules: {
		required: value => !!value || 'Required.',
		min: v => v && v.length >= 4 || 'Min 8 characters',
		emailMatch: () => (`The email and password you entered don't match`),
	    }            
        }
    },
    methods: {
        save: function($evt) {
            $evt.preventDefault();
            $evt.stopPropagation();
            console.log("Here user",this.edituser);
            this.post(this.edituser.toJSON(), window.location.href, data => console.log("Saved successfully"), err => console.log(err))
            return false;
        },
        back: function() {
            this.$router.go(-1)
        }
    }
})
