import BaseComponent from '../base/component'
import SiteStore from '../../models/sitestore'
import SiteVue from '../../site'
import Offender from '../../models/offender'

export default BaseComponent.extend({
    data: function() {
        var store = (this.$root as SiteVue).store as SiteStore
        this.fetch()
        return {
            offender: store.offender,
            store: store
        }
    },
    methods: {
        save: function($evt) {
            $evt.preventDefault();
            $evt.stopPropagation();
            console.log("Here offender",this.offender);
            this.post(this.offender.toJSON(), window.location.href, data => console.log("Saved successfully"), err => console.log(err))
            return false;
        },
        back: function() {
            this.$router.go(-1)
        }
    }
})
