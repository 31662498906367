import BaseComponent from '../base/component'

export default BaseComponent.extend({
    data: function () {
	return {
	    focusEl: null
	}
    },
    methods: {
	isActive: function(el) {
	    return this.focusEl == `#${el}`
	},
	runLabels: function () {
	    this.focusEl = "#labels";
	    this.cmd("/api/labels", () => { this.focusEl = null	})
	},

	runFaces: function () {
	    this.focusEl = "#faces";
	    this.cmd("/api/faces", () => { this.focusEl = null	})
	},

	runTexts: function () {
	    this.focusEl = "#texts";
	    this.cmd("/api/text", () => { this.focusEl = null	})
	},

	runPreprocess: function () {
	    this.focusEl = "#preprocess";
	    this.cmd("/api/process", () => { this.focusEl = null	})
	},

	runCache: function () {
	    this.focusEl = "#cache";
	    this.cmd("/api/images", () => { this.focusEl = null	})
	},

	runPurge: function () {
	    this.focusEl = "#purge";
	    this.cmd("/api/purge", () => { this.focusEl = null	})
	},

	runImport: function () {
	    this.focusEl = "#import";
	    this.cmd("/api/importupdate", () => { this.focusEl = null })
	}
    }
});

