import { define, auto } from 'type-r'
import BaseComponent from '../../base/component'
import _ from 'underscore'
import SiteVue from '../../../site'
import {Record} from '../../../models/base'
import moment, {Moment} from 'moment'

export default BaseComponent.extend({
    data: function () {
	var store = (this.$root as SiteVue).store;
        //store.config.on("update", (this as any).updateConfig, this)
	return {
	    headers: [
		{ text: "Name", value: 'name' },
		{ text: "Email", value: 'email' },
                { text: '', value: 'editbtn' }
	    ],
	    options: { },
            config: store.config,
	    totalItems: 0,
	    items: [],
	    loading: true
	}
    },
    watch: {
	options: {
	    handler() {
		this.getDataFromApi()
	    },
	    deep: true
	}
    },
    methods: {
	getDataFromApi: function() {
	    this.loading = true;
	    this.apiCall().then(data => {
		this.items = data.items;
		this.totalItems = data.totalItems;
		this.loading = false
                this.$vuetify.goTo(this.$refs.table, 500)
	    }).catch(err => {
		console.error(err);
	    })
	},
	apiCall: function() {
	    return new Promise((resolve, reject) => {		
		this.post(this.options, '/users/list', (d) => {
		    resolve(d)
		}, err => {
		    reject(err)
		});
	    })
	}
    }
});

