import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VInput } from 'vuetify/lib/components/VInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "color paddings color-dark", attrs: { id: "login" } },
    [
      _c(
        VContainer,
        [
          _c(
            VRow,
            [
              _c(VCol, { attrs: { cols: "12" } }, [
                _c("h2", [_vm._v("Login")]),
                _c("div", { staticClass: "divider_dark divider-dark-color" }),
                _c(
                  "form",
                  {
                    ref: "form",
                    staticClass: "form-horizontal",
                    attrs: {
                      id: "login",
                      method: "POST",
                      enctype: "application/x-www-form-urlencoded",
                    },
                    on: { submit: _vm.submit },
                  },
                  [
                    _c(
                      VContainer,
                      [
                        _c(VInput, {
                          attrs: {
                            type: "hidden",
                            name: "confirmation_code",
                            value: "confirmation_code",
                          },
                        }),
                        _c(
                          VCol,
                          { attrs: { cols: "12" } },
                          [
                            _c(VTextField, {
                              attrs: {
                                type: "text",
                                name: "user",
                                placeholder: "Enter your email address here",
                                label: "E-mail address",
                              },
                              model: {
                                value: _vm.email,
                                callback: function ($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          VCol,
                          { attrs: { cols: "12" } },
                          [
                            _c(VTextField, {
                              attrs: {
                                type: "password",
                                name: "pass",
                                model: _vm.pass,
                                "append-icon": _vm.show1
                                  ? "mdi-eye"
                                  : "mdi-eye-off",
                                rules: [_vm.rules.required, _vm.rules.min],
                                type: _vm.show1 ? "text" : "password",
                                label: "Normal with hint text",
                              },
                              on: {
                                "click:append": function ($event) {
                                  _vm.show1 = !_vm.show1
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          VCol,
                          { attrs: { cols: "12" } },
                          [
                            _c(VCheckbox, {
                              attrs: {
                                type: "checkbox",
                                label: "Remember Me",
                                name: "rememberMe",
                                model: _vm.rememberMe,
                                value: "rememberMe",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          VCol,
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              VBtn,
                              { attrs: { color: "primary", type: "submit" } },
                              [_vm._v("Login")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            VRow,
            [
              _c(VCol, { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "p10 mt-15 pr-30 pl-15" },
                  [
                    _c("router-link", { attrs: { to: "/reset-password" } }, [
                      _vm._v("Click here if you forgot your password"),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }