import { auto, type, define, Record } from 'type-r'

@define
export class Config extends Record {
    @auto tagOptions: any[]
    @auto reviewOptions: {[key:string]: string}
    @auto stateOptions: {[key:string]: string}
    @auto labelOptions: string[]
    @auto userOptions: {[key:string]: string}
}

var config = new Config({
    tagOptions: [
        "IMG_LOAD_FAIL",
        "IMG_LANDSCAPE_REVIEW",
        "FACE_EMPTY_FAIL",
        "FACE_MULTIPLE_FAIL",
        "LABELS_MAX_REVIEW",
        "LABELS_BLACKLIST_FAIL",
        "FACEMARKS_EMPTY_FAIL",
        "FACE_BW_FAIL",
        "FACE_SKEW_FAIL",
        "FACE_CONFIDENCE_FAIL",
        "FACE_SHARPNESS_REVIEW",
        "FACE_SHARPNESS_FAIL",
        "FACE_BRIGHTNESS_FAIL",
        "FACE_ABSMINWIDTH_FAIL",
        "FACE_MINWIDTH_FAIL",
        "FACE_ABSMINWIDTH_REVIEW",
        "FACE_MINWIDTH_REVIEW"
    ],
    reviewOptions: {
        "unreview": "UnReview",
        "pass": "Pass",
        "review": "Review",
        "fail": "Fail"
    }
}, { parse: true });

export default config;
