class hullabaloo {
    hullabaloos: any
    success: boolean
    options: any

    constructor() {
        this.hullabaloos = [];
        this.success = false;
        this.options = {
            el: "body",
            offset: {
                from: "top",
                amount: 20
            },
            align: "right",
            width: 250,
            delay: 5000,
            allow_dismiss: true,
            stackup_spacing: 10,
            text: "Произошла неизвестная ошибка.",
            icon: {
                success: "fa fa-check-circle",
                info: "fa fa-info-circle",
                warning: "fa fa-life-ring",
                danger: "fa fa-exclamation-circle",
                light: "fa fa-sun",
                dark: "fa fa-moon"
            },
            status: "danger",
            alertClass: "",
            fnStart: false,
            fnEnd: false,
            fnEndHide: false
        };
    }

    send(text, status, group = 1, id = -1) {
        if (typeof this.options.fnStart == "function")
            this.options.fnStart();

        var self = this;
        var flag = 1;
        var i = +this.hullabaloos.length - 1;
        var parent;
        var hullabaloo: any = this.generate(text, status, id);

        if (group && this.hullabaloos.length) {
            while (i >= 0 && flag) {
                if (this.hullabaloos[i].text == hullabaloo.text && this.hullabaloos[i].status == hullabaloo.status) {
                    parent = this.hullabaloos[i];
                    flag = 0;

                    hullabaloo.elem.css(this.options.offset.from, parseInt(parent.elem.css(this.options.offset.from)) + 4);
                    hullabaloo.elem.css(this.options.align, parseInt(parent.elem.css(this.options.align)) + 4);
                }
                i--;
            }
        }

        if (typeof parent == 'object') {
            clearTimeout(parent.timer);
            parent.timer = setTimeout(function () {
                self.closed(parent);
            }, this.options.delay);
            hullabaloo.parent = parent;
            parent.hullabalooGroup.push(hullabaloo);
        } else {
            hullabaloo.position = parseInt(hullabaloo.elem.css(this.options.offset.from));
            hullabaloo.timer = setTimeout(function () {
                self.closed(hullabaloo);
            }, this.options.delay);
            this.hullabaloos.push(hullabaloo);
        }
        hullabaloo.elem.fadeIn();
        if (typeof this.options.fnEnd == "function")
            this.options.fnEnd();
    }

    closed(hullabaloo) {
        var self = this;
        var idx, i, move, next;

        if ("parent" in hullabaloo) {
            hullabaloo = hullabaloo.parent;
        }
        if (this.hullabaloos !== null) {
            idx = $.inArray(hullabaloo, this.hullabaloos);
            if (idx == -1) return;

            if (!!hullabaloo.hullabalooGroup && hullabaloo.hullabalooGroup.length) {
                for (i = 0; i < hullabaloo.hullabalooGroup.length; i++) {
                    $(hullabaloo.hullabalooGroup[i].elem).remove();
                }
            }
            var id = this.hullabaloos[idx].id;
            $(this.hullabaloos[idx].elem).fadeOut("slow", function () {
                $(this).remove();
            });

            if (idx !== -1) {
                next = idx + 1;
                if (this.hullabaloos.length > 1 && next < this.hullabaloos.length) {
                    move = this.hullabaloos[next].position - this.hullabaloos[idx].position;

                    for (i = idx; i < this.hullabaloos.length; i++) {
                        this.animate(self.hullabaloos[i], parseInt(self.hullabaloos[i].position) - move);
                        self.hullabaloos[i].position = parseInt(self.hullabaloos[i].position) - move
                    }
                }

                this.hullabaloos.splice(idx, 1);
                if (typeof this.options.fnEndHide == "function")
                    this.options.fnEndHide(id);
            }
        }
    }

    animate(hullabaloo, move) {
        var self = this;
        var timer, position, i, group = 0;
        position = parseInt(hullabaloo.elem.css(self.options.offset.from));
        group = hullabaloo.hullabalooGroup.length;

        timer = setInterval(frame, 2);
        function frame() {
            if (position == move) {
                clearInterval(timer);
            } else {
                position--;
                hullabaloo.elem.css(self.options.offset.from, position);

                // Если это группа алертов
                if (group) {
                    for (i = 0; i < group; i++) {
                        hullabaloo.hullabalooGroup[i].elem.css(self.options.offset.from, position + 5);
                    }
                }
            }
        }
    }

    generate(text, status, id) {
        var alertsObj = {
            id: id,
            icon: "",
            status: status || this.options.status,
            text: text || this.options.text,
            elem: $("<div>"),
            hullabalooGroup: []
        };
        var option, offsetAmount, css;
        var self = this;

        option = this.options;
        alertsObj.elem.attr("class", "hullabaloo alert " + option.alertClass);
        alertsObj.elem.addClass("alert-" + alertsObj.status);

        if (option.allow_dismiss) {
            alertsObj.elem.addClass("alert-dismissible");
            alertsObj.elem.append("<button class=\"close\" type=\"button\" id=\"hullabalooClose\" aria-label=\"Close\"><span aria-hidden=\"true\">&times;</span></button>");
            $("#hullabalooClose", $(alertsObj.elem)).bind("click", function () {
                self.closed(alertsObj);
            });
        }

        alertsObj.icon = option.icon[alertsObj.status];
        alertsObj.elem.append("<i class=\"" + alertsObj.icon + "\"></i> " + alertsObj.text);
        offsetAmount = option.offset.amount;

        $(".hullabaloo").each(function () {
            offsetAmount = Math.max(offsetAmount, parseInt($(this).css(option.offset.from).toString()) + $(this).outerHeight() + option.stackup_spacing);
            return;
        });

        css = {
            "position": (option.el === "body" ? "fixed" : "absolute"),
            "margin": 0,
            "z-index": "9999",
            "display": "none"
        };
        css[option.offset.from] = offsetAmount + "px";
        alertsObj.elem.css(css);

        if (option.width !== "auto") {
            alertsObj.elem.css("width", option.width + "px");
        }
        $(option.el).append(alertsObj.elem);
        switch (option.align) {
            case "center":
                alertsObj.elem.css({
                    "left": "50%",
                    "margin-left": "-" + (alertsObj.elem.outerWidth() / 2) + "px"
                });
                break;
            case "left":
                alertsObj.elem.css("left", "20px");
                break;
            default:
                alertsObj.elem.css("right", "20px");
        }

        return alertsObj;
    }
};


// var hulla =new hullabaloo();

export default hullabaloo;
