import BaseComponent from '../base/component'

export default BaseComponent.extend({
    data: function() {
	return {
	    show1: false,
	    rules: {
		required: value => !!value || 'Required.',
		min: v => v.length >= 4 || 'Min 8 characters',
		emailMatch: () => (`The email and password you entered don't match`),
	    },
	    rememberMe: false,
	    email: "",
	    pass: "",
	    error: null
	}		
    },
    methods: {
	submit: function (evt) {
	    evt.preventDefault();
	    var form = this.$refs.form;
	    var fd = new FormData(form)
	    this.post(fd, '/login/auth')
	    return false;
	}
    }
});

