import BaseComponent from '../base/component'
import Config from '../../models/config'

export default BaseComponent.extend({
    props: [],
    data: function () {
        var config = new Config()
        this.fetch()
	return {
            config: config,
            submitting: false
	}
    },
    methods: {
        initFn: function() {
            this.fetch()
        },
	save: function (evt) {
            evt.preventDefault()
            evt.stopPropagation()
            this.submitting = true;
	    this.post(this.config.toJSON(), '/settings', (data) => {
                this.submitting = false;
	    })
            return false;
	}
    }
});

