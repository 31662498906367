import "reflect-metadata";
import { IOPromise, startIO } from 'type-r';
import { define, Collection, Record as Rec, type, auto, shared, memberOf, subsetOf } from 'type-r'
import _ from 'underscore'

@define
export default class Col<R extends Record = Record> extends Collection<R> {
    fetch( a_options? ) : IOPromise<this> {
        const options = { parse : true, ...a_options };
        var endpoint = this.getEndpoint();

        return startIO(
            this,
            endpoint.list( options, this ),
            options,

            json => {
                let result : any = this.set( json, { parse : true, ioMethod : 'fetch', ...options } );
                if( options.liveUpdates ){
                    result = this.liveUpdates( options.liveUpdates );
                }
                return result;
            }
        );
    }

    removeAll():Promise<void> {
        return new Promise<void>((resolve, reject)=> {

        })
    }

}

@define
export class Record extends Rec {
    static Collection = Col<Record>;

    getStore() {
        var s = super.getStore();
        return s;
    }
}
