import {define, type, auto } from 'type-r'
import Collection, {Record} from './base'
import _ from 'underscore'

@define 
export class OffenderCollection<R extends Offender = Offender> extends Collection<R> {

}

@define
export default class Offender extends Record {
    @auto offender_id: number
    @auto("Front") image_location: string
    @auto image_description: string

    @auto("") first_name: string
    @auto("") last_name: string
    @auto("") gender: string
    @auto("") description: string
    @auto("") comment: string
    @auto("") login: string
    @auto created_at: Date
    @auto last_modified: Date
    @auto last_updated_by: string
    @auto last_updated_at: Date
    @auto original_filename: string
    @auto s3_path: string
    @auto filename: string
    @auto signed_url: string
    @auto state: string
    @auto modifiedBy: string
    @auto(null) reviewStatus: string // pass, review, fail
    @auto(null) suggestedStatus: string // pass, review, fail
    @auto([]) tags: string[]
    @auto("") tagstr: string
    
    initialize() {
        this.tagstr = this.tags.join(",")
    }

    parse(data) {
        if (data.tags && data.tags.length)
            data.tagstr = data.tags.join(",")
        return data;
    }
    
    toJSON() {
        var data = super.toJSON();
        data.tags = _.map(data.tagstr.split(","), m => m.trim())
        return data;
    }
}
