import { define, auto } from 'type-r'
import {Record} from './base'

@define
export default class FilterModel extends Record {
    @auto("") bulkReview: string
    @auto("") search: string
    @auto("") user: string
    @auto("") state: string
    @auto([]) tags: string[]
    @auto([]) reviewed: string[]
    @auto([0,100]) range: number[]
}
