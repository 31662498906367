import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRangeSlider } from 'vuetify/lib/components/VRangeSlider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    [
      _c("h2", [_vm._v("Settings")]),
      _c(
        VRow,
        [
          _c(
            VCol,
            { attrs: { cols: "6" } },
            [
              _c(VTextField, {
                attrs: { label: "SMTP Server" },
                model: {
                  value: _vm.config.SMTP_SERVER,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "SMTP_SERVER", $$v)
                  },
                  expression: "config.SMTP_SERVER",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "6" } },
            [
              _c(VTextField, {
                attrs: { label: "SMTP Port" },
                model: {
                  value: _vm.config.SMTP_PORT,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "SMTP_PORT", $$v)
                  },
                  expression: "config.SMTP_PORT",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "6" } },
            [
              _c(VTextField, {
                attrs: { label: "SMTP Username" },
                model: {
                  value: _vm.config.SMTP_USER,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "SMTP_USER", $$v)
                  },
                  expression: "config.SMTP_USER",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "6" } },
            [
              _c(VTextField, {
                attrs: { label: "SMTP Password" },
                model: {
                  value: _vm.config.SMTP_PASS,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "SMTP_PASS", $$v)
                  },
                  expression: "config.SMTP_PASS",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "12" } },
            [
              _c(VTextField, {
                attrs: { label: "Offender Image Directory" },
                model: {
                  value: _vm.config.OFFENDER_IMAGEDIR,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "OFFENDER_IMAGEDIR", $$v)
                  },
                  expression: "config.OFFENDER_IMAGEDIR",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "6" } },
            [
              _c(VTextField, {
                attrs: { label: "Site URL" },
                model: {
                  value: _vm.config.SITE_URL,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "SITE_URL", $$v)
                  },
                  expression: "config.SITE_URL",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "6" } },
            [
              _c(VTextField, {
                attrs: { label: "Site Domain" },
                model: {
                  value: _vm.config.SITE_DOMAIN,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "SITE_DOMAIN", $$v)
                  },
                  expression: "config.SITE_DOMAIN",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "12" } },
            [
              _c(VTextField, {
                attrs: { label: "Site Title" },
                model: {
                  value: _vm.config.SITE_TITLE,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "SITE_TITLE", $$v)
                  },
                  expression: "config.SITE_TITLE",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "6" } },
            [
              _c(VTextField, {
                attrs: { label: "Google ReCaptcha Site Key" },
                model: {
                  value: _vm.config.G_SITEKEY,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "G_SITEKEY", $$v)
                  },
                  expression: "config.G_SITEKEY",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "6" } },
            [
              _c(VTextField, {
                attrs: { label: "Google ReCaptcha Secret Key" },
                model: {
                  value: _vm.config.G_SECRETKEY,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "G_SECRETKEY", $$v)
                  },
                  expression: "config.G_SECRETKEY",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "12" } },
            [
              _c(VTextField, {
                attrs: { label: "System Email" },
                model: {
                  value: _vm.config.SYSTEM_EMAIL,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "SYSTEM_EMAIL", $$v)
                  },
                  expression: "config.SYSTEM_EMAIL",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "12" } },
            [
              _c(VTextField, {
                attrs: { label: "Admin Email" },
                model: {
                  value: _vm.config.ADMIN_EMAIL,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "ADMIN_EMAIL", $$v)
                  },
                  expression: "config.ADMIN_EMAIL",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "12" } },
            [
              _c(VTextField, {
                attrs: { label: "Minimum Face Width" },
                model: {
                  value: _vm.config.minimumFaceWidth,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "minimumFaceWidth", $$v)
                  },
                  expression: "config.minimumFaceWidth",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "12" } },
            [
              _c(VSlider, {
                attrs: {
                  label: "Max Mean Diff",
                  "thumb-label": "always",
                  min: "0",
                  max: "100",
                },
                model: {
                  value: _vm.config.maxmeandiff,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "maxmeandiff", $$v)
                  },
                  expression: "config.maxmeandiff",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "12" } },
            [
              _c(VRangeSlider, {
                attrs: {
                  label: "Face Ratio",
                  "thumb-label": "always",
                  min: "0",
                  max: "2",
                  step: "0.10",
                },
                model: {
                  value: _vm.config.faceratio,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "faceratio", $$v)
                  },
                  expression: "config.faceratio",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "12" } },
            [
              _c(VSlider, {
                attrs: {
                  label: "Face Confidence",
                  "thumb-label": "always",
                  min: "0",
                  max: "100",
                },
                model: {
                  value: _vm.config.faceconfidence,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "faceconfidence", $$v)
                  },
                  expression: "config.faceconfidence",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "12" } },
            [
              _c(VSlider, {
                attrs: {
                  label: "Sharpness",
                  "thumb-label": "always",
                  min: "0",
                  max: "100",
                },
                model: {
                  value: _vm.config.sharpness,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "sharpness", $$v)
                  },
                  expression: "config.sharpness",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "12" } },
            [
              _c(VRangeSlider, {
                attrs: {
                  label: "Brightness",
                  "thumb-label": "always",
                  min: "0",
                  max: "100",
                },
                model: {
                  value: _vm.config.brightness,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "brightness", $$v)
                  },
                  expression: "config.brightness",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "12" } },
            [
              _c(VTextField, {
                attrs: { label: "Max Labels" },
                model: {
                  value: _vm.config.maxLabels,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "maxLabels", $$v)
                  },
                  expression: "config.maxLabels",
                },
              }),
            ],
            1
          ),
          _c(VBtn, { on: { click: _vm.goBack } }, [_vm._v("Back")]),
          _c(
            VBtn,
            { attrs: { loading: _vm.submitting }, on: { click: _vm.save } },
            [_vm._v("Save")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }