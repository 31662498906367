import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "user" } },
    [
      _c(
        VContainer,
        [
          _c(
            VRow,
            [
              _c(
                VCol,
                { attrs: { cols: "12" } },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "12" } },
                        [
                          _c(VBtn, { attrs: { to: "/users/create" } }, [
                            _vm._v("Create User"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12" } },
                        [
                          _c(VDataTable, {
                            ref: "table",
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.items,
                              "item-key": "id",
                              loading: _vm.loading,
                              "server-items-length": _vm.totalItems,
                              options: _vm.options,
                            },
                            on: {
                              "update:options": function ($event) {
                                _vm.options = $event
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item.name",
                                fn: function ({ item }) {
                                  return [
                                    _vm._v(
                                      _vm._s(item.name.first) +
                                        " " +
                                        _vm._s(item.name.last)
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item.editbtn",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/users/" + item.id } },
                                      [_vm._v("Edit")]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "body.append",
                                fn: function () {
                                  return undefined
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }