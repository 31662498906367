import { Record } from 'type-r'
import Vue from 'vue'
import _ from "underscore"
import SiteVue from "../../site"

export default Vue.extend({
    data: function () {
        return {
            title: '',
            subtitle: '',
            app: window.app,
            url: new URL(window.location.href)
        };
    },
    computed: {
        user: function () {
            return (this.$root as InstanceType<typeof SiteVue>).current_user;
        }
    },
    watch: {
        $route: function (to, from) {
            this.initFn();
        }
    },
    methods: {
        fetch: function (url?, data?) {
            url = url || window.location.href;
            data = data || {};
            data.vue = 1;
            $.ajax({
                url: url,
                data,
                cache: false,
                success: (data) => {
                    data = this.procmsg(data);
                    this.fetched(data);
                },
                error: (code, data) => {
                    this.errored(code, data);
                },
                type: 'get'
            });
        },
        procmsg: function (data) {
            if (data.success && data.success.length) {
                for (var x = 0; x < data.success.length; x++) {
                    (this.$root as InstanceType<typeof SiteVue>).hulla.send(data.success[x], 'success');
                }
            }
            if (typeof data.error == 'object') {
                for (var k in data.error) {
                    k = k.toString();
                    if (!k.match(/^[0-9]+$/))
                        (this.$root as InstanceType<typeof SiteVue>).hulla.send(`Field ${k.replace(/_/g, " ")} is required and must contain a value.`, 'danger');
                    else
                        (this.$root as InstanceType<typeof SiteVue>).hulla.send(`${data.error[k]}`, 'danger');
                }
            }
            else if (data.error) {
                console.error("procmsg err: ", data);
                (this.$root as InstanceType<typeof SiteVue>).hulla.send(data, 'danger');
            }
	    
	    if (data.info && data.info.url) {
		window.location.href = data.info.url;
	    }
            return data.data;
        },
        goBack: function() {
            this.$router.go(-1);
        },
        procerr: function (data) {
            if (typeof data == 'string') {
                console.error("procerr err: ", data);
                (this.$root as InstanceType<typeof SiteVue>).hulla.send(data, 'danger');
            }
            else {
                console.error("procerr err: ", data);
                if (data.error && data.error.length) {
                    for (var x = 0; x < data.error.length; x++) {
                        (this.$root as InstanceType<typeof SiteVue>).hulla.send(data.error[x], 'danger');
                    }
                }
            }
        },
        fetched: function (data) {
            for (var k in data) {
                if (this.hasOwnProperty(k)) {
                    if (this[k] instanceof Record)
                        this[k].set(data[k], { parse: true });
                    else if (this[k] instanceof Record.Collection) {
                        if (typeof data[k] == 'object')
                            this[k].set(_.values(data[k]), { parse: true });
                        else
                            this[k].set(data[k], { parse: true });
                    }
                    else {
                        this[k] = data[k];
                    }
                }
            }
        },
        post: function (data?, url?, cb?, errcb?) {
            url = url || window.location.href;
            data = data || {};
            let ajaxopts: JQuery.AjaxSettings = {
                url: url,
                data: data,
                cache: false,
                success: (data) => this.posted(this.procmsg(data)),
                error: (code, data) => this.errored(code, data),
                type: 'post',
                method: 'post'
            };
            if (data instanceof FormData) {
                ajaxopts.processData = false;
                ajaxopts.contentType = false;
                (ajaxopts.data as FormData).append('vue', '1');
            }
            else {
                (ajaxopts.data as any).vue = 1;
            }
            if (cb) {
                ajaxopts.success = (data) => cb(this.procmsg(data));
            }
            if (errcb) {
                ajaxopts.error = (code, txt) => errcb(this.procmsg(data));
            }
            $.ajax(ajaxopts);
        },
        posted: function (data) {

        },
        cmd: function (url?, data?, cb?) {
            if (typeof data == 'function') {
                cb = data;
                data = null;
            }
            url = url || window.location.href;

            data = data || {};
            data.vue = 1;
            $.ajax({ url: url, cache: false, data, success: (data) => cb ? cb(this.procmsg(data)) : this.cmdfinish(this.procmsg(data)), error: (code, data) => this.errored(code, data), type: 'get' });
        },
        cmdfinish: function (data) {

        },
        errored: function (code, txt) {
            (this.$root as InstanceType<typeof SiteVue>).hulla.send('Error: ' + txt, 'danger');
        },
        initFn: function () {
        },
        afterUpdate: function (cb) {
            $(document).ready(() => cb());
        },
        htmlDecode: function (txt) {
            return window.htmlDecode(txt);
        },
        params: function(name) {
            return this.url.searchParams.get(name);
        }
    }
})
