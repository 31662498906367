import { define, auto } from 'type-r'
import BaseComponent from '../base/component'
import _ from 'underscore'
import SiteVue from '../../site'
import {Record} from '../../models/base'
import moment, {Moment} from 'moment'
import FilterModel from '../../models/filter'

export default BaseComponent.extend({
    data: function () {
	var store = (this.$root as SiteVue).store;
        //store.config.on("update", (this as any).updateConfig, this)
        var filter = new FilterModel({range: [0, 100]});
        filter.on("change", (this as any).getDataFromApi, this);
	return {
	    headers: [
		{ text: '', value: '' },
		{ text: 'Photo', value: 'photo' },
		{ text: "Name", value: 'name' },
		{ text: "Gender", value: 'gender' },
		{ text: "Login", value: 'login' },
                { text: "Description", value: 'description' },
		{ text: 'Tags', value: 'tagstr' },
		{ text: 'Text', value: 'textstr' },
                { text: 'Last Modified', value: 'last_updated_at' },
                { text: 'Modified By', value: 'modifiedByStr' },
		{ text: '', value: 'btn' },
                { text: '', value: 'editbtn' }
	    ],
            bulkReview: "",
	    filter: filter,
	    options: { },
            config: store.config,
	    totalItems: 0,
	    items: [],
            showbulk: false,
	    loading: true
	}
    },
    watch: {
	options: {
	    handler() {
		this.getDataFromApi()
	    },
	    deep: true
	}
    },
    methods: {
        removeTag: function(offender, tag) {
            var idx = offender.tags.indexOf(tag);
            offender.tags.splice(idx, 1)
	    this.post({ offender: offender }, `/offender/${offender.id}`, data => console.log(`Tag removed ${tag}`), err => console.error(""))            
        },
        uniquetags: function(tags) {
            return JSON.parse(JSON.stringify(tags));
        },
        uniquetexts: function(texts) {
            var txts = {}
            return _.filter(texts, t => {
                var exists = txts[t.detectedText] == 1;
                txts[t.detectedText] = 1;
                return !exists;
            })
        },
        selchange: function() {            
            this.showbulk = _.keys(this.$refs.table.selection).length > 0;
            console.log("SHOWBULK",this.showbulk)            
        },
	getDataFromApi: function(event, instant = true) {
            this.showbulk = _.keys(this.$refs.table.selection).length > 0;
            console.log("SHOWBULK",this.showbulk)
            if (this.timerCounter) {
                clearTimeout(this.timerCounter);
                this.timerCounter = null;
            }
            
            this.timerCounter = setTimeout(() => {
	        this.loading = true;
	        this.apiCall().then(data => {
		    this.items = data.items;
		    this.totalItems = data.totalItems;
		    this.loading = false
                    this.$vuetify.goTo(this.$refs.table, 500)
	        }).catch(err => {
		    console.error(err);
	        })
            }, instant ? 5 : 500);
	},
	apiCall: function() {
	    return new Promise((resolve, reject) => {		
		this.post(_.extend(this.options, this.filter.toJSON()), '/offender/list', (d) => {
		    resolve(d)
		}, err => {
		    reject(err)
		});
	    })
	},
	renderTags: function(item) {
	    var arr = []
	    if (item.tags && item.tags.length) {
		arr = _.reduce(item.tags.sort(), (memo: string[], lbl) => {
		    memo.push(lbl);
		    return memo;
		}, []);
	    }
	    return arr.join('<br/>');	    
	},
	renderLabels: function (item) {
            var arr = [];
	    if (item.labels) {
                console.log("RENDERTAGS2");                
	         arr = _.reduce(item.labels.sort(), (memo: string[], lbl) => {
		memo.push(`${lbl.confidence.toFixed(0)}  - ${lbl.name}`);
		return memo;
	    }, []);
	    }
	    return arr.join('<br/>');
	},
	renderTexts: function (item) {
            var arr = [];
	    if (item.texts) {
	        arr = _.reduce(item.texts.sort(), (memo: string[], txt) => {
		memo.push(`${txt.confidence.toFixed(0)} - ${txt.detectedText}`);
		return memo;
	    }, []);
	    }
	    return arr.join('<br/>')
	},
        username: function(userid) {
            var u = _.find(this.config.userOptions, user => {
                return user.id == userid
            })
            return u ? u.email : '';
        },
        lastUpdatedAt: function(item, format) {
            return moment(item.last_updated_at).format(format)
        },
	loadCanvas: function (evt, offender) {
	    var img:HTMLImageElement = evt.target as HTMLImageElement;
	    var canvas:HTMLCanvasElement = evt.target.nextSibling as HTMLCanvasElement;	    
	    if (!img || !canvas) return;

	    var ctx:CanvasRenderingContext2D = canvas.getContext("2d") as CanvasRenderingContext2D;
	    
	    var ht = img.height;
	    var wt = img.width;
	    var masterStatus = "";
	    canvas.width = wt;
	    canvas.height = ht;
	    $(canvas).css("max-width", "100%");
	    $(canvas).css("height", "auto");
	    $(img).hide();
	    offender.tags = [];

	    ctx.drawImage(img, 0, 0);
	    var imgd = ctx.getImageData(0, 0, wt, ht);
	    var pix = imgd.data;

	    _.each(offender.faceDetails, (face, idx) => {
		if (face.marks.length) {
		    var tLeft = _.find(face.marks, function (mark) { return mark.type == "eyeLeft"; });
		    var tRight = _.find(face.marks, function (mark) { return mark.type == "eyeRight"; });
		    var bLeft = _.find(face.marks, function (mark) { return mark.type == "mouthLeft"; });
		    var bRight = _.find(face.marks, function (mark) { return mark.type == "mouthRight"; });

		    var x = tLeft.x * wt;
		    var y = tLeft.y * ht;
		    var ww = (tRight.x - tLeft.x) * wt;
		    var hh = (bLeft.y - tLeft.y) * ht;

		    ctx.beginPath();
		    ctx.rect(x, y, ww, hh);
		    ctx.lineWidth = 1;
		    ctx.strokeStyle = 'blue';
		    ctx.stroke();
		}

		var x = face.boundingBox.left * wt;
		var y = face.boundingBox.top * ht;
		var ww = face.boundingBox.width * wt;
		var hh = face.boundingBox.height * ht;

		// ctx.beginPath();
		// ctx.rect(x, y, ww, hh);
		// ctx.lineWidth = 3;
		// var status = offender.reviewStatus ? offender.reviewStatus : offender.suggestedStatus;

		// if (status == "fail") {
		//     ctx.strokeStyle = 'red';
		// }
		// else if (status == "review") {
		//     ctx.strokeStyle = 'yellow';
		// }
		// else {
		//     ctx.strokeStyle = 'green';
		// }
		// ctx.stroke();
	    });

	    // _.each(offender.texts, (text) => {
	    //     var x = text.geometry.boundingBox.left * wt;
	    //     var y = text.geometry.boundingBox.top * ht;
	    //     var ww = text.geometry.boundingBox.width * wt;
	    //     var hh = text.geometry.boundingBox.height * ht;

	    //     ctx.beginPath();
	    //     ctx.rect(x, y, ww, hh);
	    //     ctx.lineWidth = 3;
	    //     if (text.confidence > 98)
	    //         ctx.strokeStyle = 'green';
	    //     else if (text.confidence < 80)
	    //         ctx.strokeStyle = 'red';
	    //     else
	    //         ctx.strokeStyle = 'yellow';
	    //     ctx.stroke();
	    // });
	},
	isActive: function(item, status) {
	    return item.reviewStatus == status
	},
	export: function (evt) {
	    this.cmd("/offender/export", (data) => this.exportDone(data))
	},
	exportDone: function (data) {
	    if (data && data.length) {
		var fnm = "offenderExport.csv";
		this.exportToCsv(fnm, data);
	    }
	},
	reviewItem: function (evt, offender) {
	    this.post({ id: offender.id, status: offender.reviewStatus }, "/offender/reviewone")
	},
        clearItem: function(evt, offender, status) {
            if (offender.reviewStatus == status) {
                offender.reviewStatus = ""
            }
        },
	bulkSubmit: function () {
            this.loading = true;
	    this.post({ op: this.bulkReview, ids: _.keys(this.$refs.table.selection) }, "/offender/bulksubmit", data => {
                this.$refs.table.selection = {};
                this.getDataFromApi()
            }, err => {
                console.log("Error during bulk submit")
            });
	}
    }
});

