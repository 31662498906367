import BaseComponent from '../base/component'
import SiteVue from '../../site'

export default BaseComponent.extend({
    data: function() {
        var loggedin = (this.$root as SiteVue).store.user.id
        return {
            loggedin: loggedin
        }
    },
    methods: {
    }
})
