import {define, type, auto} from 'type-r'
import {Record} from './base'

@define
export default class Config extends Record {
    @auto SMTP_SERVER: string
    @auto SMTP_PORT: number
    @auto SMTP_USER: string
    @auto SMTP_PASS: string
    @auto("") METADATA_FILE: string
    @auto("") OFFENDER_IMAGEDIR: string
    @auto("") APPPATH: string
    @auto("") SITE_URL: string
    @auto("") SITE_DOMAIN: string
    @auto("") SITE_TITLE: string
    @auto("") G_SITEKEY: string
    @auto("") G_SECRETKEY: string
    @auto("") SYSTEM_EMAIL: string
    @auto("") ADMIN_EMAIL: string
    @auto(15) maxmeandiff: number
    @auto([.75, 1.5]) faceratio: number[]
    @auto(80) faceconfidence: number
    @auto(95) sharpness: number
    @auto([90, 110]) brightness: number[]
    @auto(120) minimumFaceWidth: number
    @auto(4) maxLabels: number
}
