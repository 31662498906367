import BaseComponent from '../base/component'

export default BaseComponent.extend({
    props: ['confirmation_code'],
    data: function () {
	return {
	    code: this.confirmation_code,
            email: "",
            submitted: false,
            submitting: false
	}
    },
    methods: {
        initFn: function() {
            this.submitted = false;
            this.submitting = false;
        },
	submit: function (evt) {
            evt.preventDefault()
            evt.stopPropagation()
	    var form = this.$refs.form;
            this.submitting = true;
	    this.post({email: this.email, code: this.code}, '/reset-password', (data) => {
                this.submitting = false;
                this.submitted = true;
		this.showMsg("Your new password has been e-mailed to you.");
	    })
            return false;
	}
    }
});

